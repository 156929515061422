import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import DonateButton from "../DonateButton";
import { Link } from "react-router-dom";

import "../../main.css";

interface Props {
  label: string;
}

const NavBar: React.FC<Props> = () => {
  return (
    <AppBar position="fixed" color="primary">
      <Toolbar>
        <Link to="/" style={{ flexGrow: 1 }}>
          <Typography variant="h6">TDotCode</Typography>
        </Link>
        <DonateButton />
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
