import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    height: "90vh",
    display: "flex",
  },
});

const Github = () => {
  const classes = useStyles();
  window.location.replace("https://www.github.com/floydjones1");
  return (
    <div className={classes.root}>
      <Typography style={{ margin: "auto" }} variant="h4" align="center">
        Redirecting you to Github...
      </Typography>
    </div>
  );
};

export default Github;
