import "react-app-polyfill/ie11";
import ReactDOM from "react-dom";
import Routes from "./routes";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";

// import default style
import "./main.css";

const Main = () => {
  return (
    <ThemeProvider theme={theme}>
      <Routes />
    </ThemeProvider>
  );
};

const rootElement = document.getElementById("root");
ReactDOM.render(<Main />, rootElement);
