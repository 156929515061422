import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";

import "./main.scss";

const useStyles = makeStyles({
  divider: {
    marginBottom: "1.5rem",
  },
});

const SectionOne: React.FC = () => {
  const classes = useStyles();
  return (
    <Grid container direction="column" className="root">
      <Typography variant="h3">Work Experience</Typography>
      <Divider className={classes.divider} />

      <Typography variant="h6">
        Alida CXM – Full Stack Software Developer (React/Golang)
      </Typography>
      <ul>
        <li>
          <Typography variant="body1" paragraph>
            <strong>Optimized</strong> web app to deliver a much snappier
            experience to users by using techniques like code splitting and
            smart image loading. Lighthouse performance score went from 68 to 91
          </Typography>
        </li>
        <li>
          <Typography variant="body1" paragraph>
            <strong>Lead</strong> and delivered a quarterly feature by leading
            epic breakdown meetings and helped clear roadblocks by interfacing
            with UX and Project managers to help deliver quality features to
            drive customer engagement. (Stack: React, Golang,
            react-testing-library, Kubernetes)
          </Typography>
        </li>
        <li>
          <Typography variant="body1" paragraph>
            <strong>Developed</strong> a slack hook that generated a one click
            link for deploying a certain version of a codebase to production,
            which would usually take developers 10min to create and prone to
            error.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" paragraph>
            <strong>Lead</strong> and implemented a mono-repo configuration with
            lerna to help several apps share the same code to help eliminate
            consistency problems between applications. (Stack: Lerna, React)
          </Typography>
        </li>
        <li>
          <Typography variant="body1" paragraph>
            <strong>Developed</strong> a Lambda function running express and
            puppeteer to scrape multiple production domains to check website
            health and generated a webpage with a high-level overview of stats.
            (Stack: AWS Lambda, express, puppeteer, API Gateway)
          </Typography>
        </li>
      </ul>

      <Typography variant="h6">
        Alida CXM – Software Developer Co-op (React/Golang)
      </Typography>
      <ul>
        <li>
          <Typography variant="body1" paragraph>
            <strong>Proactive</strong> in picking up and fixing bugs reported by
            customers/clients in a timely manner to ensure that customers get
            the best experience using VC products. (Stack: Golang, React)
          </Typography>
        </li>
      </ul>

      <Typography variant="h6">
        Slalom build – Software Engineer Intern (Full-Stack)
      </Typography>
      <ul>
        <li>
          <Typography variant="body1" paragraph>
            <strong>Tasked</strong> to develop and implement modules for Drupal
            to facilitate a content moderation workflow and trigger sending
            emails on various content states to alert admins of activity.
            Integrate Vue.js and develop APIs to help drive pages for a modern
            web application feel. (Stack: Drupal, Vuejs, Bitbucket, PHP)
          </Typography>
        </li>

        <li>
          <Typography>
            <strong>Re-engineered</strong> existing dashboard in Angular to
            retrieve employee’s birthdays from Cosmo DB instead of manually
            updating birthdays for the month to help reduce workload required to
            manage. (Stack: Angular, Express, Typescript, SCSS, Bitbucket)
          </Typography>
        </li>
      </ul>

      <Typography variant="h6">
        Thales Railway Signalling Company – C++ Software Developer
      </Typography>
      <ul>
        <li>
          <Typography variant="body1" paragraph>
            <strong>Re-engineered</strong> a key C++ application used for
            railway logic routing; outcome of this project reduced the time it
            takes for the application to processes and output hundreds of
            Boolean images and equations by approximately 80%.
          </Typography>
        </li>

        <li>
          <Typography variant="body1" paragraph>
            <strong>Developed</strong> a full stack web application allowing
            authenticated managers to enter their employees’ hours towards a
            project on a monthly basis which allowed directors to better track
            employee utilization metrics. (Stack: PHP, jQuery, EC2, Bootstrap)
          </Typography>
        </li>
      </ul>
    </Grid>
  );
};

export default SectionOne;
