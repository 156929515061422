import Container from "@material-ui/core/Container";
import Section1 from "../components/Section/one";
import Section2 from "../components/Section/two";
import Section3 from "../components/Section/three";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    height: "100%",
  },
  section1: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    minHeight: "100vh",
    background: "#EFF6FF",
  },
  section2: {
    color: "#ffffff",
    background: "#0C2D48",
    padding: "2.5rem 0rem",
    minHeight: "100vh",
  },
  section3: {
    background: "#EFF6FF",
    padding: "2.5rem 0rem",
    minHeight: "100vh",
  },
});

const Home = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.section1}>
        <Container maxWidth="lg" className={classes.root}>
          <Section1 />
        </Container>
      </div>
      <div className={classes.section2}>
        <Container maxWidth="lg" className={classes.root}>
          <Section2 />
        </Container>
      </div>
      <div className={classes.section3}>
        <Container maxWidth="lg" className={classes.root}>
          <Section3 />
        </Container>
      </div>
    </>
  );
};

export default Home;
