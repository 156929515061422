import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import ProjectCard from "../ProjectCard";
import algorithmPic from "../../assets/algorithm-project.png";
import Typography from "@material-ui/core/Typography";
import Carousel from "react-material-ui-carousel";

const items = [
  {
    name: "Random Name #1",
    description: "Probably the most random thing you have ever seen!",
  },
  {
    name: "Random Name #2",
    description: "Hello World!",
  },
];
const API_KEY = "AIzaSyAgSO9pBnFw99tBnvhnktRLBSTZh7-RicI";
const CHANNEL_ID = "UCUwA7VxRo-uw2eQJ52EkKlQ";

type YTVideo = {
  publishedAt: string;
  channelId: string;
  title: string;
  description: string;
  thumbnails: {
    high: {
      url: string;
      width: number;
      height: number;
    };
  };
  channelTitle: string;
};

type YTId = {
  id: string;
  videoId: string;
};

const order = [
  "date",
  "rating",
  "relevance",
  "title",
  "videoCount",
  "viewCount",
];

const SectionTwo: React.FC = () => {
  const [videos, setVideos] = useState<Array<Project>>([]);

  useEffect(() => {
    const num = Math.floor(Math.random() * order.length);
    const randomOrder = order[num];
    fetch(
      `https://www.googleapis.com/youtube/v3/search?key=${API_KEY}&channelId=${CHANNEL_ID}&part=snippet,id&order=${randomOrder}&maxResults=20`
    )
      .then((res) => res.json())
      .then((data) => {
        const ytVids: Array<Project> = data.items.map(
          ({ snippet, id }: { snippet: YTVideo; id: YTId }) => ({
            imgUrl: snippet.thumbnails.high.url,
            title: snippet.title,
            description: snippet.description,
            url: `https://www.youtube.com/watch?v=${id.videoId}`,
          })
        );
        setVideos([...ytVids, ...PROJECTS]);
        console.log(ytVids);
      });
  }, []);

  return (
    <Grid container>
      <Typography variant="h3" style={{ margin: "1.5rem 2rem 3rem 2rem" }}>
        Projects
      </Typography>
      <Carousel>
        {videos.map((proj, idx) => (
          <ProjectCard {...proj} key={idx} />
        ))}
      </Carousel>
    </Grid>
  );
};

type Project = {
  imgUrl: string;
  url: string;
  title: string;
  description: string;
  inApp?: boolean;
};

const PROJECTS: Array<Project> = [
  {
    imgUrl: algorithmPic,
    url: "https://floydjones1.github.io/algorithm/",
    title: "Algorithms",
    description:
      "Project that uses ChartJS to show different sorting Algorithms",
  },
  {
    imgUrl: "https://miro.medium.com/max/2560/1*W3DhD-sRnFxgzRNHvd21ZQ.png",
    url: "https://github.com/floydjones1/ExpressTSCognito",
    title: "AWS Cognito ExpressTS",
    description: "Setup project that just needs to be configured for usage.",
  },
  {
    imgUrl:
      "https://embedwistia-a.akamaihd.net/deliveries/fee5063331832c55fcb54729b9e65ebc9f9ed4c1.webp?image_crop_resized=1280x720",
    url: "/3D",
    title: "Three-D Playground",
    description:
      "I'm not a 3D/game developer but here is playground where I keep trying to learn some",
    inApp: true,
  },
];

export default SectionTwo;
