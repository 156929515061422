import React from "react";

const DonateButton: React.FC = () => {
  return (
    <form action="https://www.paypal.com/donate" method="post" target="_top">
      <input type="hidden" name="business" value="U8VR9CYBEQ5J4" />
      <input
        type="hidden"
        name="item_name"
        value="Software Engineering Content"
      />
      <input type="hidden" name="currency_code" value="CAD" />
      <input
        type="image"
        src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif"
        name="submit"
        title="PayPal - The safer, easier way to pay online!"
        alt="Donate with PayPal button"
      />
      <img
        alt=""
        src="https://www.paypal.com/en_CA/i/scr/pixel.gif"
        width="1"
        height="1"
      />
    </form>
  );
};

export default DonateButton;
