import React from "react";
import { useHistory } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

interface Props {
  title: string;
  description: string;
  imgUrl: string;
  url: string;
  inApp?: boolean;
}

const ProjectCard: React.FC<Props> = (props) => {
  const { title, description, imgUrl, url, inApp } = props;
  const history = useHistory();

  const openLink = () => {
    if (inApp) {
      history.push(url);
    } else {
      window.open(url);
    }
  };
  return (
    <Card raised>
      <CardActionArea onClick={openLink}>
        <CardMedia
          component="img"
          alt="Contemplative Reptile"
          image={imgUrl}
          style={{ objectFit: "cover" }}
          title="Contemplative Reptile"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ProjectCard;
