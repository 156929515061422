import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ReactPlayer from "react-player";
import SocialMediaButtons from "react-social-media-buttons";

import "./main.scss";

const links = [
  "https://github.com/floydjones1",
  "https://www.youtube.com/channel/UCUwA7VxRo-uw2eQJ52EkKlQ",
];

const buttonStyle = {
  backgroundColor: "#0C2D48",
  borderRadius: "50%",
  margin: "0rem 0.75rem",
};

const iconStyle = { color: "#ffffff" };

const SectionOne: React.FC = () => {
  return (
    <Grid container className="root">
      {/* <ReactPlayer
        style={{ position: "absolute", height: "100%", width: "100%" }}
        controls={false}
        width="100%"
        height="100%"
        autoPlay
        url="https://www.youtube.com/watch?v=yoqFr7Ml0Ck&feature=youtu.be"
      /> */}
      <Grid container item xs={12} sm={6} justify="center">
        <div className="section1">
          <div className="section1__img"></div>
          <Typography variant="h3" align="center" className="section1__title">
            Hello! I'm Floyd Jones 👋 <br></br> I'm a Full Stack Software
            Engineer!
          </Typography>
        </div>
      </Grid>
      <Grid
        container
        item
        xs={12}
        sm={6}
        direction="column"
        alignContent="space-around"
        justify="space-around"
        className="section1__experience"
      >
        <Grid item>
          <Typography variant="h3" align="center">
            Experience
          </Typography>
          <br></br>
          <Typography variant="h5" align="center">
            Full Stack Software Engineer with <strong>3 years</strong> of
            experience skilled in ReactJS/VueJS Golang, PHP, Node, Docker and
            various Cloud services.
          </Typography>
        </Grid>
        <Grid item container justify="center" style={{ marginTop: "1rem" }}>
          <SocialMediaButtons
            links={links}
            buttonStyle={buttonStyle}
            iconStyle={iconStyle}
            openNewTab
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SectionOne;
