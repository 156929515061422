import React from "react";

const LINK =
  "https://www.paypal.com/donate/?business=U8VR9CYBEQ5J4&item_name=Software+Engineering+Content&currency_code=CAD";
const Donate = () => {
  React.useEffect(() => {
    window.location.replace(LINK);
  }, []);
  return <></>;
};

export default Donate;
